import styled, { css } from "styled-components";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { device } from "../../theme";

export const ProjectWrapper = styled.div`
  margin-top: ${(props) => props.mt};
  flex: 1 1 50%;
  ${(props) =>
    props.responsive &&
    css`
      ${(props) =>
        props.responsive.medium &&
        css`
          @media ${device.medium} {
            flex-basis: 100%;
            margin-top: ${(props) => props.responsive.medium.mt};
          }
        `};
    `}
`;

export const ProjectBlock = styled(BackgroundImage)`
  height: 440px;
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease;

  .overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.6);
    transition: background 0.4s ease;
    z-index: 1;
  }

  .contentWrapper {
    position: relative;
    padding: 150px 100px;
    bottom: 0;
    transition: all 0.4s ease;

    h3 {
      margin: 0;
      padding-bottom: 20px;
      color: #ececec;
      font-size: 1.3em;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.1;
      letter-spacing: 3.5px;
    }

    p {
      margin: 0;
      padding-bottom: 45px;
      color: #ececec;
    }

    button {
      position: relative;
      display: inline-block;
      padding: 8px 38px;
      background: transparent;
      color: #ececec;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      font-size: 0.9em;
      line-height: 1.8em;
      border-radius: 0;
      transition: all 0.5s;
      border: 2px solid #ececec;
      overflow: hidden;
      text-decoration: none;
      opacity: 0;

      :after {
        content: "";
        background: #ececec;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100%;
        transition: top 0.3s;
        z-index: -1;
      }

      :hover {
        color: #111;
        :after {
          top: 0;
        }
      }
    }
  }

  :hover {
    .overlay {
      background: rgba(17, 17, 17, 0.85);
    }

    .contentWrapper {

      button {
        opacity: 1;
      }
    }
  }
  @media ${device.small} {
    .contentWrapper {
      padding: 100px 50px;
    }
    &:hover {
      .contentWrapper {
        bottom: 50px;
      }
    }
  }
`;

export const Thumb = styled.div`
  background: #ccc url(${(props) => props.bgimg});
  position: relative;
  z-index: 2;
  margin-left: 0px;
  display: inline-block;
  margin-bottom: 46px;
  width: 100%;
  max-width: 1062px;
  @media ${device.xlarge} {
    margin-left: 0;
    width: 100%;
  }
  @media ${device.small} {
    margin-bottom: 30px;
  }
  @media ${device.xsmall} {
    margin-bottom: 25px;
  }
  a {
    display: block;
  }
  img {
    border-radius: 0px;

    @media ${device.xlarge} {
      width: 100%;
    }
  }
`;

export const ProjectBtnWrap = styled.div`
  position: absolute !important;
  right: -65px;
  bottom: 0;
  @media ${device.medium} {
    right: 0;
  }
`;

export const ProjectBtn = styled(Link)`
  background: ${(props) => props.theme.colors.primary};
  width: 130px;
  display: block;
  height: 70px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  line-height: 75px;
  font-size: 36px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  @media ${device.small} {
    width: 70px;
    height: 45px;
    line-height: 55px;
    font-size: 12px;
    border-radius: 0px;
  }
  i {
    color: #ffffff;
    transition: all 0.4s;
    z-index: 5;
    display: inline-block;
    @media ${device.small} {
      font-size: 20px;
    }
  }
  &:before {
    top: 0;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    width: 0;
    z-index: 1;
    transition: all 0.4s;
    background: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    &:before {
      animation: slide-effect 0.7s cubic-bezier(0.7, 0.15, 0.34, 0.87) both;
      width: 100%;
    }
    i {
      transform: translateX(10px);
    }
  }
`;

export const ProjectType = styled.div`
  flex-basis: 33.33%;
  @media ${device.small} {
    flex-basis: 100%;
    padding: 15px 0;
  }
  @media ${device.xsmall} {
    padding: 10px 0;
  }
`;
